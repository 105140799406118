// src/App.js
import React from 'react';
import PowerBIReport from './components/PowerBIReport';

function App() {
  return (
    <div>
      <PowerBIReport />
    </div>
  );
}

export default App;