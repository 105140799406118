import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './PowerBIReport.css'; // Asegúrate de importar tu CSS

const PowerBIReport = () => {
  const [embedConfig, setEmbedConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const reportId = process.env.REACT_APP_POWERBI_REPORT_ID || '1969497f-f21c-4b27-93b0-b2ff9eae34b0';
  const groupId = process.env.REACT_APP_POWERBI_GROUP_ID || '678cd4dd-ef5e-45ac-8a18-e77af1950ba6';
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://bkendpowerbi.backendsarf.com/';

  useEffect(() => {
    const fetchEmbedToken = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/getEmbedToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ reportId, groupId }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'No se pudo obtener el embed token');
        }

        const data = await response.json();
        const embedToken = data.embedToken;

        setEmbedConfig({
          type: 'report',
          id: reportId,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            layoutType: models.LayoutType.Responsive,
            toolbar: {
              visible: false,
            },
          },
        });

        setLoading(false);
      } catch (err) {
        console.error('Error al obtener el embed token:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchEmbedToken();
  }, [reportId, groupId, BACKEND_URL]);

  if (loading) {
    return (
      <div>
        <p>Cargando reporte...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <p>Error al cargar el reporte: {error}</p>
      </div>
    );
  }

  return (
    <div className="iframe-container" style={{ position: 'relative', height: '100vh', width: '100%' }}>
      {/* Overlay para ocultar la barra de notificación */}
      <div className="notification-overlay"></div>
      
      <PowerBIEmbed
        embedConfig={embedConfig}
        cssClassName="report-style-class"
        getEmbeddedComponent={(embeddedReport) => {
          console.log('Reporte embebido:', embeddedReport);
        }}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default PowerBIReport;
