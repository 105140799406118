// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Importar desde react-dom/client
import './App.css'; // Importar estilos
import App from './App';

const container = document.getElementById('root');

// Crear el root
const root = ReactDOM.createRoot(container);

// Renderizar la aplicación
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);